
import { setSubmitting } from '@/Chat/module';
import { Response } from '@/ts/interfaces/Question';
import { defineComponent } from '@vue/runtime-core';
import Chat from '@/Chat/index.vue';
import { sendResponses, sendMessage, doUpdate, collectionRef } from '../helpers';
import { doc, updateDoc } from '@firebase/firestore';

export default defineComponent({
    beforeRouteEnter(to, from, next){
        const split = from.path.split('/');
        const page = split[split.length-1];
        if(split.length > 3 && !['demographics','filled-recently'].includes(page))
            next(`/token/${to.params.token}`);
        else
            next();
    },
    computed: {
        sex(){
            return this.$store.state.user.sex;
        },
        used(){
            return Boolean(this.$store.state.firebase?.claims.usedAt);
        },
        hasLegend(){
            return this.$store.state.chat?.questions[0]?.type === 'legend';
        }
    },
    components: { Chat },
    methods: {
        async initiateSubmit(responses: Response[]){
            setSubmitting();
            const startTime = Date.now();

            try{
                const { token } = this.$route.params;
                const tokenRef = doc(collectionRef('tokens'), token as string);
                await Promise.all([
                    updateDoc(tokenRef, { usedAt: Date.now() }),
                    doUpdate('autoSaves', { done: true }),
                    sendResponses(responses, 'subjects')
                ]);
                setTimeout(() => {
                    this.$router.push('final');
                    setSubmitting();
                }, 800 - (Date.now() - startTime));
            }catch(error){
                console.error(error);
            }
		},
        sendResponses,
        sendMessage
    }
});
